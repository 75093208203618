<template>
    <div class="collect-con">
        <div class="head">
            <h3 v-if="status">商品收藏</h3>
            <h3 v-else>看相似</h3>
        </div>
        <el-divider></el-divider>
        <div class="con" v-if="datalist.length >0">
            <div v-for="item in datalist" :key="item.id">
                <div class="item">
                    <div class="left-img">
                    <img :src="item.imgurl" />
                </div>
                <div class="right-info">
                    <div class="good-title">
                        <p>{{item.product_title}}</p>
                        <i class="el-icon-delete" @click="cancleCollect(item.id)"></i>
                    </div>
                    <div class="shopname"><span>店铺：</span><p>{{item.mch_name}}</p></div>
                    <div class="price-con">
                        <div class="n-price">￥{{item.yprice}}</div>
                        <div class="o-price">￥{{item.price}}</div>
                    </div>

                    <div class="btn">
                        <el-button @click="look(item.p_id)" v-if="status">看相似</el-button>
                        <el-button @click="look(item.p_id)" v-else :disabled="true">
                            <i class="el-icon-star-on"></i>
                            已收藏
                        </el-button>
                    </div>
                </div>
                
                </div>
                <el-divider></el-divider>
                
            </div>

            <div class="similar" v-if="!status">
                <div class="head-title">相似商品</div>
                <div class="product">
                    <div class="item" v-for="(item,index) in product" :key="index" @click="toDetail(item.id)">
                        <img :src="item.imgurl" />
                        <p>{{item.product_title}}</p>
                        <span>¥{{item.price}}</span>
                    </div>
                </div>
                <div class="pagination">
                    <el-pagination
                        background
                        layout="prev, pager, next"
                        :total="total"
                        :page-size="1"
                        >
                    </el-pagination>
                </div>
                
            </div>
        </div>
        <div class="no-goods" v-else>
            <img src="@/assets/image/no_sc.png" />
            <p>你还没有收藏商品哦~</p>
        </div>
    </div>
</template>
<script>
import {onMounted,ref} from 'vue'
import {getData} from '@/utils/req'
import {useRouter} from 'vue-router'
export default {
    setup(){
        let pc_user = ref('')
        let datalist = ref('')
        let status = ref(true)
        let total = ref('')
        let product = ref('')
        const router = useRouter()
        onMounted(()=>{
            pc_user.value = JSON.parse(localStorage.getItem('pc_user'))
            axios()
        })
        const axios =()=>{
            const data={
                module: 'app_pc',
                action: 'addFavorites',
                m: 'collection',
                access_id: pc_user.value.access_id,
                type: 1
            }
            getData(data).then(res=>{
                datalist.value = res.data
            })
        }
        const cancleCollect =(id) =>{
            console.log(id)
            const data ={
                module: 'app_pc',
                action: 'addFavorites',
                m: 'removeFavorites',
                access_id: pc_user.value.access_id,
                collection: id,
                type: 1
            }
            getData(data).then(res=>{
                if(res.code ==200){
                    axios()
                }
            })
        }
        const look = (pid)=>{
            const data ={
                module: 'app_pc',
                action: 'addFavorites',
                m: 'similar',
                access_id: pc_user.value.access_id,
                pro_id: pid,
                page: 1
            }
            getData(data).then(res=>{
                status.value = false
                datalist.value = res.data.list
                total.value = res.data.total
                product.value = res.data.product
            })
        }
        const toDetail = (id) =>{
            router.push({
                path:'/homedetail',
                query: {
                     good_id:id
                }
            })
        }
        return {
            total,
            status,
            product,
            datalist,
            cancleCollect,
            look,
            toDetail
        }
    }
}
</script>
<style scoped>
    .collect-con{
        width: 960px;
        height: 100%;
        background: #fff;
        position: relative;
    }
    .collect-con .head{
        padding: 0 20px;
        display: inline-block;
        height: 60px;
        line-height: 60px;
        font-size: 16px;
        font-weight: 500;
        color: #D4282D;
        position: relative
    }
    .no-goods{
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -80px;
        margin-top: -91px;
    }
    .no-goods p{
        font-size: 16px;
        line-height: 16px;
        color: #888888;
        margin-top: 20px;
    }
    .con .item{
        display: flex;
        margin-top: 30px;
        margin-bottom: 20px;
        padding: 5px;
    }
    .con .item .left-img{
        width: 150px;
        height: 150px;
    }
    .con .item .right-info{
        flex: 1;
        margin-left: 15px;
    }
    .con .item .right-info .good-title{
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        font-size: 16px;
        line-height: 16px;
    }
    .con .item .right-info .good-title:hover{
        color: #D4282D;
        text-decoration: underline;
    }
    .con .item .right-info .good-title i{
        margin-right: 20px;
    }
    .shopname{
        display: flex;
        align-items: center;
        font-size: 14px;
        line-height: 14px;
        color: #888888;
        margin-top: 16px;
    }
    .price-con{
        display: flex;
        align-items: center;
        color: #D4282D;
        font-weight: bold;
        line-height: 14px;
        margin-top: 16px;
    }
    .price-con .o-price{
        padding-left: 10px;
        font-weight: 300;
        color: #999999;
        text-decoration: line-through;
    }
    .el-button{
        border-radius: 0px;
        width: 80px;
        height: 30px;
        line-height: 0;
        text-align: center;
        padding-left: 0;
        padding-right: 0;
        margin-top: 46px;
    }
    [class*=' el-icon-'], [class^=el-icon-]{
        line-height: 0;
    }
    .similar{
        padding-left: 20px;
        margin-top: 30px;
    }
    .similar .product{
        display: flex;
        flex-wrap: wrap;
    }
    .similar .product img{
        width: 218px;
        height: 218px;
    }
    .similar .product .item{
        width: 218px;
        display: flex;
        flex-direction: column;
    }
    .similar .product .item p{
        font-size: 14px;
        line-height: 14px;
        color: #020202;
        margin-top: 16px;
        text-align: center;
        font-weight: bold;
    }
    .similar .product .item span{
        display: block;
        text-align: center;
        color: #D4282D;
        font-size: 14px;
        line-height: 12px;
        margin-top: 12px;
        padding-bottom: 20px;
    }
    .similar .product .item:hover{
        box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 20%);
        background-color: #eeeeee;
    }
    .pagination{
        position: absolute;
        left: 50%;
        bottom:5%;
        margin-left: -10px;
    }
</style>